/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import { useLocation } from '@reach/router';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { BackButton, ForwardButton } from '@components/UI/navButtons';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { useTeamQuery } from '@querys/useTeamQuery';
import { PageMeta } from '@components/pageMeta';

const TeamMember = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const {
    fullName,
    slug,
    bioImage,
    role,
    phone,
    email,
    linkedIn,
    bioText,
    locationRef,
    seo,
  } = page || {};

  // extract first name from fullName
  const firstName = fullName?.split(' ')[0];

  const { href } = useLocation();

  const { nodes } = useTeamQuery();

  const [currentMember, setCurrentMember] = useState(0);
  const [nextMember, setNextMember] = useState(0);

  useEffect(() => {
    const currentMemberIndex = nodes?.findIndex(
      (member) => member?.slug?.current === slug?.current
    );

    setCurrentMember(currentMemberIndex);
    setNextMember(currentMemberIndex + 1);
  }, [currentMember]);

  const shareStyle = {
    display: 'inline-block',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
  };

  // create function to accept an array of locations and return a string of locations with and & between the last two, or single location
  const locationString = (locations) => {
    if (locations?.length === 1) {
      return locations[0]?.name;
    } else if (locations?.length === 2) {
      return `${locations[0]?.name} & ${locations[1]?.name}`;
    } else {
      const newlocations = locations?.map((loc) => loc?.name);
      const lastLocation = newlocations?.pop();
      const locationString = newlocations?.join(', ');
      return `${locationString} & ${lastLocation}`;
    }
  };

  return (
    <Layout reversedHeader={true} disabledHeader={true}>
      {seo && <PageMeta {...seo} />}
      <div className='px-gutter lg:px-0 lg:grid grid-cols-14 pt-[150px] lg:pt-[340px]'>
        <div className='col-start-2 col-end-8 row-start-1 lg:sticky lg:top-0 lg:self-start pt-[21px] pb-[21px]'>
          <h3 className='blockH5 text-yellow max-w-[350px]'>
            <span>{role} </span>
            <br />
            <span> {locationString(locationRef)}</span>
          </h3>
          <h1 className='blockH2 mt-4'>{fullName}</h1>
          <div className='blockH5 text-grey mt-12 flex flex-col space-y-2 '>
            {phone?.map((num, i) => (
              <a className='inline-block' href={`tel:${phone[0]}`}>
                <span className='font-medium pr-2 '>M</span>
                <span className='hover:text-yellow transition-colors duration-300'>
                  {num}
                </span>
              </a>
            ))}
          </div>
          <a
            href={`mailto:${email}`}
            className='blockH5 text-grey mt-5 inline-block'
          >
            <span className='font-medium pr-2 '>E</span>
            <span className='hover:text-yellow transition-colors duration-300'>
              {email}
            </span>
          </a>
          {linkedIn && (
            <a
              href={`${linkedIn}`}
              target='_blank'
              rel='noreferrer'
              className='blockH5 text-grey mt-5 block'
            >
              LinkedIn
            </a>
          )}
          <div className='text-grey mt-[39px]'>
            <h4 className='blockH5'>Share</h4>
            <div className='blockH5 mt-1 flex space-x-4'>
              {' '}
              <FacebookShareButton url={href} style={shareStyle}>
                Facebook
              </FacebookShareButton>
              <TwitterShareButton url={href} style={shareStyle}>
                X
              </TwitterShareButton>
              <LinkedinShareButton url={href} style={shareStyle}>
                LinkedIn
              </LinkedinShareButton>
              <EmailShareButton url={href} style={shareStyle}>
                Email
              </EmailShareButton>
            </div>
          </div>
        </div>
        <div className='col-start-7 col-end-14 row-start-1 mt-10 lg:mt-0'>
          <div className='max-w-[645px] h-auto relative rounded-[30px] overflow-hidden'>
            {bioImage && (
              <SanityImage
                image={bioImage}
                className=' top-0 left-0 w-full h-full object-cover'
              />
            )}
          </div>
          <div className='mt-10 lg:mt-[103px]'>
            <SimplePortableText className='text-grey' text={bioText} />
          </div>
          <div className='blockH5 lg:flex lg:space-x-6 mt-10'>
            <a href={`tel:${phone[0]}`} className='button-yellow'>
              Call {firstName}
            </a>
            <a href={`mailto:${email}`} className='button-yellow mt-4 lg:mt-0'>
              Email {firstName}
            </a>
          </div>
        </div>
      </div>
      <div className='flex w-full  mt-20 mx-auto lg:mx-0 pl-gutter   lg:px-gutter blockH3 flex-col md:flex-row justify-between'>
        <BackButton
          linkText='Back to team'
          linkUrl={`/team`}
          extraClasses='w-full mr-4 lg:mr-0'
        />
        {nextMember < nodes.length && (
          <ForwardButton
            linkText='See Next'
            linkUrl={`/team/${nodes[nextMember]?.slug?.current}`}
            extraClasses='w-full md:justify-end mt-5 md:mt-0'
          />
        )}
      </div>
    </Layout>
  );
};

export default TeamMember;

export const teamMemberQuery = graphql`
  query standardTeamMemberQuery($slug: String!) {
    page: sanityTeamMember(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      fullName
      slug {
        current
      }
      bioImage {
        ...ImageWithPreview
      }
      role
      phone
      email
      linkedIn
      bioText: _rawBioText(resolveReferences: { maxDepth: 10 })
      solutionRef {
        title
        slug {
          current
        }
      }
      locationRef {
        name
      }
    }
  }
`;
