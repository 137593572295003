import React, { useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

import Back from "@svg/back.svg";
import Forward from "@svg/forward.svg";

const backVariants = {
  initial: {
    x: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  hover: {
    x: [0, -5, 0],
    transition: {
      duration: 0.8,
      ease: "easeInOut",
      loop: Infinity,
    },
  },
};

const forwardVariants = {
  initial: {
    x: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  hover: {
    x: [0, 5, 0],
    transition: {
      duration: 0.8,
      ease: "easeInOut",
      loop: Infinity,
    },
  },
};

export const BackButton = ({ linkText, linkUrl, extraClasses }) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={linkUrl}
      className={`flex items-center group cursor-pointer  ${extraClasses} `}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center w-fit h-full">
        <motion.div
          variants={backVariants}
          initial="initial"
          animate={hover ? "hover" : "initial"}
          className="bg-yellow w-9 h-9  rounded-full relative"
        >
          <Back className="absolute bottom-1/2 right-1/2 translate-y-1/2 translate-x-1/2 " />
        </motion.div>
      </div>
      <p className="ml-5 group-hover:text-yellow transition-all duration-300 ease-in-out ">
        {linkText}
      </p>
    </Link>
  );
};

export const ForwardButton = ({ linkText, linkUrl, extraClasses }) => {
  const [hover, setHover] = useState(false);
  return (
    <Link
      to={linkUrl}
      className={`flex items-center group cursor-pointer  ${extraClasses} `}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center h-full  md:justify-end  ">
        <motion.div
          variants={forwardVariants}
          initial="initial"
          animate={hover ? "hover" : "initial"}
          className="bg-yellow w-9 h-9  rounded-full relative"
        >
          <Forward className="absolute  bottom-1/2 right-1/2 translate-y-1/2 translate-x-1/2 " />
        </motion.div>
      </div>
      <p className="ml-5  flex-shrink-0   group-hover:text-yellow transition-all duration-300 ease-in-out ">
        {linkText}
      </p>
    </Link>
  );
};
