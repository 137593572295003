import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useTeamQuery = () => {
  const { allSanityTeamMember } = useStaticQuery(graphql`
    query {
      allSanityTeamMember(sort: { fields: orderRank, order: ASC }) {
        nodes {
          fullName
          slug {
            current
          }
          bioImage {
            ...ImageWithPreview
          }
          role
          phone
          email
          bioText: _rawBioText(resolveReferences: { maxDepth: 10 })
          solutionRef {
            title
            slug {
              current
            }
          }
          locationRef {
            name
          }
        }
      }
    }
  `);
  return allSanityTeamMember || {};
};
